/**List of inputs
 * Input types:
 * - Text, number, email, phone, textarea, line
 * - Radio
 * - Select, multi select
 * - Checkbox
 * - Image upload
 * - Image uploader
 * 
 * - Date picker
 * - Time picker

 */

export interface Facility {
  id?: string | null; // Firestore document ID

  //DONE INPUTS
  //TEXT INPUTS
  name: string;
  city: string;
  street: string;
  zip: string;
  contactEmail: string | null;
  web: string | null;
  stripeAccountId: string | null;
  discoverCity: string;
  languageCode: string;
  phone: string[] | null;
  description: string[] | null;
  englishDescription: string[] | null;

  //NUMBER INPUTS
  latitude: number;
  longitude: number;
  interval: number;
  baseSlotHeightInMinutes: number | null;
  maxAllowedFutureReservationDays: number | null;
  maxSlotsInBooking: number | null;
  minSlotsInBooking: number | null;
  paymentPercentage: number | null;
  createdAt: number; // NOT INPUT

  //RADIO INPUTS
  isCashBookingOutsideCityEnabled: boolean;
  isPhoneRequired: boolean | null;
  isTeamGameSupported?: boolean | null;
  isPrivate: boolean; // NOT INPUT
  isLive: boolean;

  //IMAGE INPUTS
  //Single image
  logo: string;
  //Multiple images
  images: string[];

  //SELECT VALUES
  country: string;
  discoverPriority: DiscoverPriority;
  type: SportFacility;
  availablePaymentMethods: PaymentType[]; // Array of available payment methods E.g. ['inFacility', 'card']
  sports: Sport[] | null;
  amenities: Amenity[] | null; // Array of amenities E.g. ['wifi', 'parking']

  //MODAL INPUTS
  bookingLimits: BookingLimits | null;
  packagedBookingData: PackagedBookingData | null;
  stripeCancellationConfigs?: StripeCancellationConfig[] | null;
  bookingAddOns: BookingAddOn[] | null; // Array of booking add-ons
  lmsLeagueConfig?: LmsLeagueConfig | null;
  courts: Court[];
  discountConfig?: DiscountConfig | null;
  workDays: WorkDays | null;
}

export class Facility {
  constructor(data?: Facility) {
    this.id = data?.id || null;
    this.amenities = data?.amenities || null;
    this.availablePaymentMethods = data?.availablePaymentMethods || [];
    this.bookingAddOns = data?.bookingAddOns || null;
    this.city = data?.city || '';
    this.contactEmail = data?.contactEmail || null;
    this.country = data?.country || '';
    this.courts = data?.courts || [];
    this.description = data?.description || [''];
    this.englishDescription = data?.englishDescription || [''];
    this.languageCode = data?.languageCode || 'hr';
    this.discoverCity = data?.discoverCity || null;
    this.discoverPriority = data?.discoverPriority || 'low';
    this.images = data?.images || [];
    this.interval = data?.interval || 0;
    this.isCashBookingOutsideCityEnabled =
      data?.isCashBookingOutsideCityEnabled || false;
    this.isPrivate = data?.isPrivate || false;
    this.latitude = data?.latitude || 0;
    this.logo = data?.logo || '';
    this.longitude = data?.longitude || 0;
    this.name = data?.name || '';
    this.packagedBookingData = data?.packagedBookingData || null;
    this.phone = data?.phone || null;
    this.sports = data?.sports || [];
    this.street = data?.street || '';
    this.type = data?.type || 'sport';
    this.workDays = data?.workDays || {
      nonWorkingDays: [],
      workingHours: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      adminWorkingHours: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      differentWorkingHours: [],
      workSchedules: [],
    };
    this.zip = data?.zip || '';
    this.createdAt = data?.createdAt || +new Date();
    this.web = data?.web || null;

    this.stripeAccountId = data?.stripeAccountId || null;
    this.paymentPercentage = data?.paymentPercentage || null;
    this.isTeamGameSupported = data?.isTeamGameSupported || false;
    this.maxAllowedFutureReservationDays =
      data?.maxAllowedFutureReservationDays || null;
    this.minSlotsInBooking = data?.minSlotsInBooking || 1;
    this.maxSlotsInBooking = data?.maxSlotsInBooking || 1;
    this.bookingLimits = data?.bookingLimits || null;
    this.discountConfig = data?.discountConfig || null;
    this.isLive = data?.isLive || false;
    this.isPhoneRequired = data?.isPhoneRequired || null;
    this.lmsLeagueConfig = data?.lmsLeagueConfig || null;
    this.stripeCancellationConfigs = data?.stripeCancellationConfigs || null;
  }
}

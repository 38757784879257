import { useImageUploader } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import Image from 'next/image';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

interface ImageUploaderProps {
  name: string;
  storagePath: string;
  labelId?: string;
  maxFileSize?: number;
  firestorePath?: string;
  acceptedTypes?: string[];
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  name,
  storagePath,
  firestorePath,
  labelId = 'input.imageUploader.label',
  maxFileSize = 5 * 1024 * 1024,
  acceptedTypes = ['image/*'],
}) => {
  const { formatMessage } = useIntl();
  const {
    formState: { errors },
  } = useFormContext();

  const {
    isLoading,
    dropzoneProps,
    inputProps,
    localPreviewUrl,
    isMarkedForDeletion,
    handleRemove,
    handleSubmit,
  } = useImageUploader({
    name,
    storagePath,
    acceptedTypes,
    maxFileSize,
    firestorePath,
  });

  return (
    <div className="space-y-4">
      {labelId && (
        <h3 className="text-white font-semibold">
          <FormattedMessage id={labelId} />
        </h3>
      )}
      <div
        {...dropzoneProps}
        className={`border border-white border-dashed rounded-md p-4 text-center transition duration-300 ease-out ${
          isLoading
            ? 'cursor-not-allowed opacity-50'
            : 'cursor-pointer hover:border-blue-500'
        }`}
      >
        <input {...inputProps} disabled={isLoading} />
        <p className="text-white">
          {isLoading ? (
            <span className="text-white">
              <FormattedMessage id="input.processing" />
            </span>
          ) : (
            <>
              <FormattedMessage id="input.imageUploader.instructions" />
              <br />
              <span className="text-white text-sm">
                <FormattedMessage
                  id="input.imageUploader.acceptedTypes"
                  values={{
                    types: acceptedTypes.join(', '),
                  }}
                />
              </span>
              <br />
              <span>
                <FormattedMessage
                  id="input.imageUploader.maxFileSize"
                  values={{
                    size: (maxFileSize / 1024 / 1024).toFixed(2),
                  }}
                />
              </span>
            </>
          )}
        </p>
      </div>

      {localPreviewUrl && (
        <div className="relative">
          <Image
            src={localPreviewUrl}
            alt="Preview"
            width={256}
            height={256}
            className="object-cover rounded-md"
          />
          <NewButton
            type="button"
            onClick={handleRemove}
            variant="delete"
            size="small"
            className="absolute top-2 right-2"
            disabled={isLoading}
          >
            <FormattedMessage id="input.remove" />
          </NewButton>
        </div>
      )}

      {(isMarkedForDeletion || localPreviewUrl) && (
        <NewButton
          type="button"
          onClick={handleSubmit}
          variant="secondary"
          size="medium"
          disabled={isLoading}
          loading={isLoading}
        >
          {isLoading ? (
            <FormattedMessage id="input.processing" />
          ) : (
            <FormattedMessage id="input.save" />
          )}{' '}
        </NewButton>
      )}

      {errors?.[name]?.message && (
        <p className="text-red-500 text-sm">
          {typeof errors[name]?.message === 'string'
            ? errors[name].message
            : formatMessage({ id: 'input.imageUploader.invalidInput' })}
        </p>
      )}
    </div>
  );
};

import {
  useDefaultClaim,
  useIsPlayOffAdmin,
  useUserId,
  useUserIdLoading,
} from '@modules/auth';
import { useFacility } from '@modules/facility';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function usePlayoffAdminRedirects() {
  const router = useRouter();

  const [loading, setLoading] = useState(true);

  const userId = useUserId();
  const facility = useFacility();
  const userIdLoading = useUserIdLoading();
  const defaultClaim = useDefaultClaim();

  const isPlayOffAdmin = useIsPlayOffAdmin();

  useEffect(() => {
    if ((!userId && !userIdLoading) || !isPlayOffAdmin) router.push('/login');
    console.log({ facility });

    if (
      facility?.type === 'group' &&
      router.pathname.includes('booking-calendar')
    ) {
      console.log('Pushing to groups');
      router.push(`/dashboard/${defaultClaim?.role}/groups`);
    }

    if (facility?.type === 'sport' && router.pathname.includes('groups')) {
      console.log('Pushing to booking-calendar');
      router.push(`/dashboard/${defaultClaim?.role}/booking-calendar`);
    }

    if (facility) setLoading(false);
  }, [
    userIdLoading,
    userId,
    isPlayOffAdmin,
    facility,
    router,
    defaultClaim?.role,
  ]);

  return {
    loading: userIdLoading || loading,
  };
}

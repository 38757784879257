import { facilityFirestoreConverter } from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { toast } from 'sonner';

/**
 * Provides functions to create, read, update, and delete a facility document in Firestore
 * @param {string | null} userUid The user's UID
 * @returns An object containing functions to create, read, update, and delete a facility document
 * @property {function} createFacility - Creates a new facility document in Firestore
 * @property {function} updateFacility - Updates an existing facility document in Firestore
 * @property {function} deleteFacility - Deletes an existing facility document in Firestore
 * @property {function} getFacility - Gets an existing facility document from Firestore
 */

export const useFacilityDocument = (facilityId?: string) => {
  /**
   * Creates a new facility document in Firestore
   * @param newFacility The new facility data
   */
  const createFacility = async (newFacility: Facility) => {
    try {
      const facilitiesRef = collection(FirebaseService.firestore, 'facilities');
      const facilityData = facilityFirestoreConverter.toFirestore(newFacility);
      console.log({ facilityData });
      const docRef = await addDoc(facilitiesRef, facilityData);

      return docRef.id;
    } catch (error) {
      console.log({ error });
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Updates an existing facility document in Firestore
   * @param facility The updated facility data
   */
  const updateFacility = async (facility: Facility) => {
    try {
      const facilitiesRef = doc(
        FirebaseService.firestore,
        'facilities',
        facility.id,
      );
      const facilityData = facilityFirestoreConverter.toFirestore(facility);
      await setDoc(facilitiesRef, facilityData);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Delete an existing facility document in Firestore
   */
  const deleteFacilityByStatus = async (facilityId: string) => {
    try {
      const facilitiesRef = doc(
        FirebaseService.firestore,
        'facilities',
        facilityId,
      );
      await updateDoc(facilitiesRef, {
        status: 'cancelled',
        canceledBy: 'admin',
      });
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Gets an existing facility document from Firestore
   */
  const getFacility = async (): Promise<Facility | undefined> => {
    try {
      const facilitiesRef = doc(
        FirebaseService.firestore,
        'facilities',
        facilityId,
      );
      const facilitySnapshot = await getDoc(facilitiesRef);
      if (!facilitySnapshot.exists()) {
        throw new Error("Facility snapshot doesn't exist");
      }
      const facility =
        facilityFirestoreConverter.fromFirestore(facilitySnapshot);
      return facility;
    } catch (error) {
      toast.error(JSON.stringify(error));
      return undefined;
    }
  };

  return {
    createFacility,
    updateFacility,
    deleteFacilityByStatus,
    getFacility,
  };
};

import { FirebaseService } from '@modules/firebase';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { facilityFirestoreConverter } from '../utils';

export class FacilityService {
  static async getFacilities(): Promise<Facility[]> {
    const snapshot = await getDocs(
      collection(FirebaseService.firestore, 'facilities').withConverter(
        facilityFirestoreConverter,
      ),
    );
    return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  }

  static async updateFacility(
    id: string,
    data: Partial<Facility>,
  ): Promise<void> {
    await updateDoc(doc(FirebaseService.firestore, `facilities/${id}`), data);
  }
}

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const facility = atom<Facility | null | undefined>({
  key: 'facility',
  default: undefined,
});

export const facilities = atom<Facility[] | null>({
  key: 'facilities',
  default: [],
});

export const error = atom<string | undefined>({
  key: 'facility.error',
  default: undefined,
});

const loadingDocument = atom<boolean>({
  key: 'facility.loading',
  default: true,
});

const isSelectSportOpen = atom<boolean>({
  key: 'facility.isSelectSportOpen',
  default: false,
});

const currentFacilityId = atom<string | null>({
  key: 'facility.currentFacilityId',
  default: null,
});

const facilityRoles = atom<FacilityRole[] | null>({
  key: 'facility.facilityRoles',
  default: null,
});

export const facilityAtoms = {
  facility,
  facilities,
  error,
  loadingDocument,
  isSelectSportOpen,
  currentFacilityId,
  facilityRoles,
};

export const useFacility = () => useRecoilValue(facility);
export const useFacilities = () => useRecoilValue(facilities);
export const useFacilityError = () => useRecoilValue(error);
export const useFacilityLoadingDocument = () => useRecoilValue(loadingDocument);
export const useIsSelectSportOpen = () => useRecoilValue(isSelectSportOpen);
export const useCurrentFacilityId = () => useRecoilValue(currentFacilityId);
export const useFacilityRoles = () => useRecoilValue(facilityRoles);

export const useSetFacility = () => useSetRecoilState(facility);
export const useSetFacilities = () => useSetRecoilState(facilities);
export const useSetFacilityError = () => useSetRecoilState(error);
export const useSetFacilityLoadingDocument = () =>
  useSetRecoilState(loadingDocument);
export const useSetIsSelectSportOpen = () =>
  useSetRecoilState(isSelectSportOpen);
export const useSetCurrentFacilityId = () =>
  useSetRecoilState(currentFacilityId);
export const useSetFacilityRoles = () => useSetRecoilState(facilityRoles);

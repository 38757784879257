import { useIsPlayOffAdmin } from '@modules/auth';
import {
  facilityFirestoreConverter,
  useSetCurrentFacilityId,
  useSetFacilities,
} from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';

/**This hook is used to fetch all playoff facilities for playoff-admin */
export function usePlayoffFacilities() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const setFacilities = useSetFacilities();
  const isPlayOffAdmin = useIsPlayOffAdmin();
  const setCurrentFacilityId = useSetCurrentFacilityId();

  const fetchFacilities = async () => {
    try {
      const facilitiesCollection = collection(
        FirebaseService.firestore,
        'facilities',
      ).withConverter(facilityFirestoreConverter);

      const snapshot = await getDocs(facilitiesCollection);
      const facilities = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log('Facilities', facilities);
      setFacilities(facilities);
      if (facilities.length > 0) {
        console.log('Setting current facility id', facilities[0].id);
        setCurrentFacilityId(facilities[0].id);
      }
    } catch (err) {
      console.error('Error fetching facilities:', err);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('usePlayoffFacilities', isPlayOffAdmin);
    if (!isPlayOffAdmin) return;
    fetchFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayOffAdmin]);

  return { loading, error, refetch: fetchFacilities };
}

import { Facility, useSetCurrentFacilityId } from '@modules/facility';
import { useFacilityDocument } from '@modules/facility-management';
import { useLocalStorage } from '@shared/hooks';
import { Modal } from '@ui/Modal';
import { LAST_SELECTED_FACILITY_ID } from 'const';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'sonner';

interface Form {
  bookingLimits: BookingLimits | null;
}

interface Props {
  closeModal: () => void;
}

export const AddNewFacilityModal: React.FC<Props> = ({ closeModal }) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { createFacility } = useFacilityDocument();
  const setCurrentFacilityId = useSetCurrentFacilityId();
  const [, setLastSelectedVisible] = useLocalStorage<string>(
    LAST_SELECTED_FACILITY_ID,
  );

  async function handleSubmit() {
    try {
      setLoading(true);
      const newFacilityData = new Facility();
      console.log({ newFacilityData });
      const facilityId = await createFacility(newFacilityData);
      console.log({ facilityId });
      setCurrentFacilityId(facilityId);
      setLastSelectedVisible(facilityId);
      router.push(`/dashboard/playoff-admin/facility-management`);
      toast.success(formatMessage({ id: 'add-new-facility.toasts.success' }));
      closeModal();
    } catch (error) {
      toast.error(formatMessage({ id: 'add-new-facility.toasts.error' }));
      console.error('Error updating booking limits:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      onClose={closeModal}
      title={formatMessage({ id: 'add-new-facility.title' })}
      handleSubmit={handleSubmit}
      confirmText={formatMessage({ id: 'input.add' })}
      cancelText={formatMessage({ id: 'input.cancel' })}
      isLoading={loading}
    >
      <p className="text-white text-base-fluid">
        <FormattedMessage id="add-new-facility.description" />
      </p>
    </Modal>
  );
};
